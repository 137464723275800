import { gql } from '@apollo/client';
import { backendResponse } from '../../../../common_lib_front/types/backendResponse';
import { PassStatuses } from '../../../../common_lib_front/types/passInfo';

export const EDIT_VEHICLE = gql`
mutation EditVehicle(
  $vehicleId: String!,
	$make: String,
	$vehicleModel: String,
	$type: String,
	$year: Float,
	$color: String,
	$licensePlate: String,
	$fleetNumber: String,
	$isRental: Boolean,
	$licensePlateState: String,
	$primaryDriverName: String,
	$licensePrimaryDriver: String,
	$destination: String,
	$registrationDoc: String,
	$insuranceDoc: String,
	$employeeId: String,
	$employeeList: [String!],
	$name: String,
	$driverEmail: String,
	$driverPhone: String,
	$driversLicenseExp: String,
	$identificationSource: String
) {
  editVehicle(
    vehicleId: $vehicleId,
    make: $make,
    vehicleModel: $vehicleModel,
    type: $type,
    year: $year,
    color: $color,
    licensePlate: $licensePlate,
    fleetNumber: $fleetNumber,
    isRental: $isRental,
    licensePlateState: $licensePlateState,
    primaryDriverName: $primaryDriverName,
    licensePrimaryDriver: $licensePrimaryDriver,
    destination: $destination,
    registrationDoc: $registrationDoc,
    insuranceDoc: $insuranceDoc,
    employeeId: $employeeId,
    employeeList: $employeeList,
    name: $name,
    driverEmail: $driverEmail,
    driverPhone: $driverPhone,
    driversLicenseExp: $driversLicenseExp,
    identificationSource: $identificationSource
  ) {
    success
    error
  }
}
`;
export type EDIT_VEHICLE_VARS = {
  vehicleId: string,
	make?: string,
	vehicleModel?: string,
	type?: string,
	year?: number,
	color?: string,
	licensePlate?: string,
	fleetNumber?: string,
	isRental?: boolean,
	licensePlateState?: string,
	primaryDriverName?: string,
	licensePrimaryDriver?: string,
	destination?: string,
	registrationDoc?: string,
	insuranceDoc?: string,
	employeeId?: string,
	employeeList?: string[],
	name?: string,
	driverEmail?: string,
	driverPhone?: string,
	driversLicenseExp?: string,
	identificationSource?: string
}
export type EDIT_VEHICLE_RES = {
  editVehicle: backendResponse<undefined>;
}

export const EDIT_PASS = gql`
mutation EditPass(
  $addons: [String!]!
  $startDate: DateTime
  $endDate: DateTime
  $passId: String!
) {
  editPass(
    addons: $addons
    startDate: $startDate
    endDate: $endDate
    passId: $passId
  ) {
    success
    error
  }
}
`;
export type EDIT_PASS_VARS = {
  addons: string[];
  startDate: string | null;
  endDate: string | null;
  passId: string;
}
export type EDIT_PASS_RES = {
  editPass: backendResponse<undefined>
}

export const STEP2_QUERY = gql`
  query Step2 (
    $registrationId: String!
  ) {
    getRegistration (
      registrationId: $registrationId
    ) {
      success
      error
      data {
        registrationId
        complete
        renew
      }
    }
    getPassesByRegistration (
      registrationId: $registrationId
    ) {
      success
      error
      data {
        passId
        registrationId
        passInfoId
        number
        passType
        startDate
        endDate
        status
        addons
        vehicle {
          vehicleId
          make
          vehicleModel
          year
          type
          color
          licensePlate
          fleetNumber
          licensePlateState
          primaryDriverName
          licensePrimaryDriver
          destination
          employeeList
          employeeId
        }
      }
    }
    getCommunityRental (
      registrationId: $registrationId
    ) {
      success
      error
      data {
        arrivalDate
        departureDate
      }
    }
  }
`;
export type STEP2_QUERY_RES = {
  getRegistration: backendResponse<{
    registrationId: string;
    complete: boolean;
    renew: boolean;
  }[]>;
  getPassesByRegistration: backendResponse<{
    passId: string;
    passInfoId: string;
    number: string;
    passType: string;
    startDate: string;
    endDate: string;
    status: PassStatuses;
    addons: string[];
    vehicle?: {
      vehicleId: string;
      make: string;
      vehicleModel: string;
      year?: number;
      type: string;
      color: string;
      licensePlate: string;
      fleetNumber: string;
      licensePlateState: string;
      primaryDriverName: string;
      licensePrimaryDriver: string;
      destination: string;
      employeeList: string[];
      employeeId: string;
    } | null;
  }[]>;
  getCommunityRental: backendResponse<{
    arrivalDate: string;
    departureDate: string;
  }>
}

export const STEP_TWO_SUMBIT = gql`
  mutation Step2(
    $stepNumber: Float!,
    $registrationId: String!,
  ) {
    editRegistrationStepNumber (
      stepNumber: $stepNumber,
      registrationId: $registrationId,
    ) {
      success
      error
    }
  }
`;

export const SAVE_PASS = gql`
  mutation SavePass (
    $registrationId: String!
    $passInfoId: String!
    $startDate: String!
    $endDate: String!
    $addons: [String!]
  ) {
    registerPass(
      registrationId: $registrationId
      passInfoId: $passInfoId
      startDate: $startDate
      endDate: $endDate
      addons: $addons
    ) {
      success
      error
      data {
        passId
      }
    }
  }
`;
export type SAVE_PASS_RES = {
  registerPass: backendResponse<{ passId: string | null }[]>,
}

export const SAVE_VEHICLE = gql`
  mutation SaveVehicle (
    $make: String!
    $vehicleModel: String!
    $year: Float
    $type: String!
    $color: String!
    $licensePlate: String!
    $fleetNumber: String!
    $passId: String!
    $licensePlateState: String
    $primaryDriverName: String
    $licensePrimaryDriver: String
    $destination: String
    $employeeId: String
    $driverEmail: String
    $driverPhone: String
    $driversLicenseExp: String
    $identificationSource:String
    $employeeList: [String!]
  ) {
    registerVehicle (
      make: $make
      vehicleModel: $vehicleModel
      year: $year
      type: $type
      color: $color
      licensePlate: $licensePlate
      fleetNumber: $fleetNumber
      passId: $passId
      licensePlateState: $licensePlateState
      primaryDriverName: $primaryDriverName
      driverEmail: $driverEmail
      driverPhone: $driverPhone
      driversLicenseExp: $driversLicenseExp
      licensePrimaryDriver: $licensePrimaryDriver
      destination: $destination
      employeeId: $employeeId
      employeeList: $employeeList
      identificationSource:$identificationSource
    ) {
      success
      error
    }
  }
`;
export type SAVE_VEHICLE_VARS = {
  make: string;
  vehicleModel: string;
  year?: number;
  type: string;
  color: string;
  licensePlate: string;
  fleetNumber: string;
  passId: string;
  licensePlateState?: string;
  primaryDriverName?: string;
  licensePrimaryDriver?: string;
  destination?: string;
  employeeId?: string;
  driverEmail?: string;
  driverPhone?: string;
  driversLicenseExp?: string;
  identificationSource?:string;
  employeeList?: string[];
}
export type SAVE_VEHICLE_RES = {
  registerVehicle: backendResponse<undefined>;
}

export const DELETE_PASS_VEHICLE = gql`
  mutation DeletePassAndVehicle (
    $passId: String!
    $vehicleId: String!
  ) {
    deletePass (
      passId: $passId
    ) {
      success
      error
    }
    deleteVehicle (
      vehicleId: $vehicleId
    ) {
      success
      error
    }
  }
`;

export const DELETE_PASS = gql`
mutation DeletePass($passId: String!) {
  deletePass(passId: $passId) {
    success
    error
  }
}
`;
export type DELETE_PASS_VARS = {
  passId: string;
}
export type DELETE_PASS_RES = {
  deletePass: backendResponse<undefined>;
}

export const DELETE_VEHICLE = gql`
mutation DeleteVehicle($vehicleId: String!) {
  deleteVehicle(vehicleId: $vehicleId) {
    success
    error
  }
}
`;
export type DELETE_VEHICLE_VARS = {
  vehicleId: string;
}
export type DELETE_VEHICLE_RES = {
  deleteVehicle: backendResponse<undefined>;
}

export const GET_PRICE_INFO = gql`
  query GetPriceInfo($passInfoId: String!) {
    getPriceInfo(passInfoId: $passInfoId) {
      success
      error
      data {
        passInfoId
        isFree
        price
        chargePerDay
        discount
        discountPrice
        allowCashOrCheck
        addons {
          name
          isFree
          price
        }
      }
    }
  }
`;
